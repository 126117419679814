import { DateTime } from "luxon";
import {
  INQUIRY_KANBAN_MODE,
  INQUIRY_KANBAN_DATE,
} from "../actionTypes/kanbanTypes";

const initialState = {
  startTime: DateTime.now().minus({ days: 7 }).toString(),
  endTime: DateTime.now().plus({ days: 7 }).toString(),

  searchTerm: "",
  mode: "normal",
};

const inquiryKanbanReducer = (state = initialState, action) => {
  if (action === undefined) return state;

  if (action.kanban === undefined) return state;

  switch (action.type) {
    case INQUIRY_KANBAN_MODE:
      return { ...state, mode: action.payload.mode };
    case INQUIRY_KANBAN_DATE:
      return {
        ...state,
        startTime: action.payload.startTime,
        endTime: action.payload.endTime,
      };

    default:
      return state;
  }
};

export default inquiryKanbanReducer;
