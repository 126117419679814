import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "react-notifications/lib/notifications.css";

import "core-js";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { icons } from "./assets/icons";

import store, { persistor } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { NotificationContainer } from "react-notifications";
import axios from "axios";
import { ENVIROMENT } from "./config";
import { CustomProvider } from "rsuite";
React.icons = icons;

axios.interceptors.request.use((request) => {
  request.baseURL = ENVIROMENT.remoteUrl;
  let token = store.getState().authReducer.token;

  request.headers.Authorization = "Bearer " + token;
  request.headers["Content-Type"] = "application/json";
  return request;
});

ReactDOM.render(
  <CustomProvider theme="dark">
    <Provider store={store}>
      <NotificationContainer />
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </CustomProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
