import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import {
  AuthReducer,
  JobKanbanReducer,
  ListReducer,
  notificationReducer,
  tenantReducer,
  invoiceKanbanReducer,
  inquiryKanbanReducer,
  quoteKanbanReducer,
} from "./redux/reducers";
import { createTransform, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  sidebarShow: "responsive",
};
const persistConfig = {
  key: "root",
  storage,
};

const layoutReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  authReducer: AuthReducer,
  listReducer: ListReducer,
  layoutReducer,
  tenantReducer: tenantReducer,
  notificationReducer: notificationReducer,
  jobKanbanReducer: JobKanbanReducer,
  invoiceKanbanReducer: invoiceKanbanReducer,
  inquiryKanbanReducer: inquiryKanbanReducer,
  quoteKanbanReducer: quoteKanbanReducer,
});

const store = createStore(
  persistReducer(persistConfig, rootReducer),
  applyMiddleware(thunk)
);
export default store;

export const persistor = persistStore(store);
