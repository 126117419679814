const initialState = {
  listStates: {},
};

const listReducer = (state = initialState, action) => {
  if (action === undefined) return state;

  if (action.list === undefined) return state;

  let newListState = { ...state.listStates };
  newListState[action.type] = {
    page: action.payload.page,
    pageSize: action.payload.pageSize,
    searchTerm: action.payload.searchTerm,
  };
  return {
    ...state,
    lastUpdated: action.type,
    listStates: newListState,
  };
};

export default listReducer;
