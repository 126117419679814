import jwtDecode from "jwt-decode";
import { LOGIN_USER_SUCCESS } from "../actionTypes/actionTypes";

const initialState = {
    user: undefined,
    token: undefined,
    expiry: undefined
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER_SUCCESS:

            var decodedToken = jwtDecode(action.token);
            return {
                ...state,
                token: action.token,
                user: action.user,
                expiry: decodedToken.exp
            }
            
        default: return state;
    }
}

export default authReducer;