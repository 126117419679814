import {
  SET_NOTIFICATIONS,
  SET_UNREAD_AMOUNT,
} from "../actionTypes/notificationTypes";
import axios from "axios";
export const getNotifications = () => {
  return async function (dispatch) {
    let notifications = await axios.get("/Notification/GetNotifications");
    if (notifications.status !== 200) {
      return;
    }

    let unread = await axios.get("/Notification/Unread");
    if (unread.status !== 200) {
      return;
    }
    dispatch(setNotifications(notifications.data, unread.data));
  };
};

export const resetNotifications = () => {
  return async function (dispatch) {
    let notifications = await axios.get("/Notification/ReadAll");
    if (notifications.status !== 200) {
      return;
    }
    dispatch(setUnread(0));
  };
};

export const setNotifications = (notifications, unread) => {
  return {
    type: SET_NOTIFICATIONS,
    payload: {
      unread: unread,
      notifications: notifications,
    },
  };
};

export const setUnread = (unread) => {
  return {
    type: SET_UNREAD_AMOUNT,
    payload: {
      unread: unread,
    },
  };
};
