import { DateTime } from "luxon";
import { QUOTE_KANBAN_DATE, QUOTE_KANBAN_MODE } from "../actionTypes/kanbanTypes";

const initialState = {
  startTime: DateTime.now().minus({ days: 7 }).toString(),
  endTime: DateTime.now().plus({ days: 7 }).toString(),

  searchTerm: "",
  mode: "normal",
};

const quoteKanbanReducer = (state = initialState, action) => {
  if (action === undefined) return state;

  if (action.kanban === undefined) return state;

  switch (action.type) {
    case QUOTE_KANBAN_MODE:
      return { ...state, mode: action.payload.mode };
    case QUOTE_KANBAN_DATE:
      return {
        ...state,
        startTime: action.payload.startTime,
        endTime: action.payload.endTime,
      };

    default:
      return state;
  }
};

export default quoteKanbanReducer;
