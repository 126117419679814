import { DateTime } from "luxon";
import { useMocks } from "src/config";
import store from "../store";


const requireLogin = (to, from, next) => {
    const { expiry, token } = store.getState().authReducer;
    let isValid = expiry > DateTime.now().toSeconds() && token !== "";


    if (useMocks && to.location.pathname === '/login') {
        next.redirect("/");
        return;
    }

    if (useMocks === true) {
        next();
        return;
    }

    if (isValid && to.location.pathname === '/login') {
        next.redirect("/");
        return;
    }

    if (!to.meta.auth) {
        next();
        return;
    }

    if (isValid) {
        next();
        return;
    }
    next.redirect("/login")

}

export default requireLogin;