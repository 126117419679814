import {
  SET_NOTIFICATIONS,
  SET_UNREAD_AMOUNT,
} from "../actionTypes/notificationTypes";

const initialState = {
  notifications: [],
  unreadAmount: 0,
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {
        notifications: action.payload.notifications,
        unreadAmount: action.payload.unread,
      };

    case SET_UNREAD_AMOUNT:
      return {
        ...state,
        unreadAmount: action.payload.unread,
      };
    default:
      return state;
  }
};
