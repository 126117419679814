import { SET_TENANT } from "../actionTypes/";

const initialState = {
  settings: undefined,
};

export const tenantReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TENANT:
      return { ...state, settings: action.payload };
    default:
      return state;
  }
};
