import axios from "axios";
import React, { Component, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Router,
  Switch,
  HashRouter,
} from "react-router-dom";
import "@elastic/eui/dist/eui_theme_amsterdam_dark.css";
import "pattern.css";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/image-editor/dist/style.css";
import history from "./history";

import "./scss/style.scss";
import { GuardProvider, GuardedRoute } from "react-router-guards";

import authGuard from "./guards/authGuard";
import { useDispatch } from "react-redux";
import { getNotifications } from "./redux/actions/notificationActions";

const loading = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const CustomerInvoiceView = React.lazy(() =>
  import("src/views/pages/invoices/CustomerInvoiceView/CustomerInvoiceView")
);
const CustomerQuoteView = React.lazy(() =>
  import("src/views/pages/quotes/CustomerQuoteView/CustomerQuoteView")
);
axios.interceptors.response.use((response) => {
  if (response.status === 401 && response.config.url !== "/Token") {
    history.push("/login");
    return;
  }
  return response;
});
const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  return (
    <div>
      <HashRouter history={history}>
        <GuardProvider guards={[authGuard]} loading={loading} error={Page404}>
          <React.Suspense fallback={loading}>
            <Switch>
              <GuardedRoute
                exact
                path="/login"
                name="Login Page"
                component={(props) => <Login {...props} />}
              />

              <GuardedRoute
                path="/invoices/my-invoice"
                exact
                name="My Invoice"
                component={(props) => <CustomerInvoiceView {...props} />}
                meta={{ auth: false }}
              />
              <GuardedRoute
                path="/quotes/my-quote"
                exact
                name="My Quote"
                component={(props) => <CustomerQuoteView {...props} />}
                meta={{ auth: false }}
              />

              <GuardedRoute
                exact
                path="/register"
                name="Register Page"
                component={(props) => <Register {...props} />}
              />
              <GuardedRoute
                exact
                path="/404"
                name="Page 404"
                component={(props) => <Page404 {...props} />}
              />
              <GuardedRoute
                exact
                path="/500"
                name="Page 500"
                component={(props) => <Page500 {...props} />}
              />
              <GuardedRoute
                path="/"
                name="Home"
                component={(props) => <TheLayout {...props} />}
                meta={{ auth: true }}
              />
            </Switch>
          </React.Suspense>
        </GuardProvider>
      </HashRouter>
    </div>
  );
};

export default App;
