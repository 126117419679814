export const useDev =  false;
export const useMocks =  false;
export const ENVIROMENT = {
  remoteUrl:
    useDev !== false
      ? "http://localhost:44301/api/"
      : "https://api.edwardscontractors.co.uk/api/",
  remoteGenUrl:
    useDev !== false
      ? "http://localhost:44301"
      : "https://api.edwardscontractors.co.uk",
  imageUrl:
    useDev !== false
      ? "http://localhost:44301/api/thumbnail/"
      : "https://api.edwardscontractors.co.uk​/api/thumbnail/",

  fileUrl:
    useDev !== false
      ? "http://localhost:44301/api/upload/"
      : "https://api.edwardscontractors.co.uk​/api/upload/",
  fileDownloadUrl:
    useDev !== false
      ? "http://localhost:44301/api/file/"
      : "https://api.edwardscontractors.co.uk​/api/file/",
  defaultImage:
    useDev !== false
      ? "http://localhost:44301/api/thumbnail/logo.jpg"
      : "https://api.edwardscontractors.co.uk​/api/thumbnail/logo.jpg", 
  invoicePdfUrl:
      useDev !== false
        ? "http://localhost:44301/api/InvoicePrint/GetPdf/"
        : "https://api.edwardscontractors.co.uk​/api/InvoicePrint/GetPdf/",
    quotePdfUrl:
      useDev !== false
        ? "http://localhost:44301/api/QuotePrint/GetPdf/"
        : "https://api.edwardscontractors.co.uk​/api/QuotePrint/GetPdf/",
  
};
